import styled, { keyframes } from "styled-components";

const glitch = keyframes`
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-5px, 5px)
}
  40% {
    transform: translate(-5px, -5px)
}
  60% {
    transform: translate(5px, 5px)
}
  80% {
    transform: translate(5px, -5px)
}
  to {
    transform: translate(0);
  }
`;

const hotlinebg = keyframes`
    0% {
      background-position: 23% 0;
  }
    50% {
      background-position: 78% 100%;
  }
    100% {
      background-position: 23% 0;
  }
`;

const pulse = keyframes`
  0% {
    text-shadow: rgba(255, 255, 255, .9) -0.06em -0.06em, rgba(67, 144, 126, .9) 0 0;
}
  33% {
    text-shadow: rgba(204, 255, 252, .9) -0.15em -0.15em, rgba(157, 94, 186, .9) 0 0;
}
  66% {
    text-shadow: rgba(229, 255, 254, .9) -0.06em -0.06em, rgba(157, 94, 186, .9) 0 0;
}
  100% {
    text-shadow: rgba(179, 255, 251, .9) -0.15em -0.15em, rgba(202, 69, 215, .9) 0 0;
}
`;

const deadtext = keyframes`
  0% {
  -webkit-transform: rotateY(30deg) rotateX(-20deg);
  text-shadow: 10px 10px 3px #f022ff,9px 9px 3px #f022ff,8px 8px 2px #f022ff,7px 7px 2px #f022ff,6px 6px 1px #f022ff,5px 5px 1px #f022ff,4px 4px 0 #f022ff,3px 3px 0 #f022ff,2px 2px 0 #f022ff,1px 1px 0 #f022ff
}

  25% {
  -webkit-transform: rotateY(-30deg);
  text-shadow: -10px -10px 3px #f022ff,-9px -9px 3px #f022ff,-8px -8px 2px #f022ff,-7px -7px 2px #f022ff,-6px -6px 1px #f022ff,-5px -5px 1px #f022ff,-4px -4px 0 #f022ff,-3px -3px 0 #f022ff,-2px -2px 0 #f022ff,-1px -1px 0 #f022ff
}

  50% {
  -webkit-filter: hue-rotate(20deg) brightness(400%);
  -webkit-transform: rotateY(30deg) rotateX(20deg);
  text-shadow: -10px 10px 3px #f022ff,-9px 9px 3px #f022ff,-8px 8px 2px #f022ff,-7px 7px 2px #f022ff,-6px 6px 1px #f022ff,-5px 5px 1px #f022ff,-4px 4px 0 #f022ff,-3px 3px 0 #f022ff,-2px 2px 0 #f022ff,-1px 1px 0 #f022ff
}

  75% {
  -webkit-transform: rotateY(-30deg);
  text-shadow: 10px -10px 3px #f022ff,9px -9px 3px #f022ff,8px -8px 2px #f022ff,7px -7px 2px #f022ff,6px -6px 1px #f022ff,5px -6px 1px #f022ff,4px -4px 0 #f022ff,3px -3px 0 #f022ff,2px -2px 0 #f022ff,1px -1px 0 #f022ff
}

  to {
    -webkit-transform: rotateY(30deg) rotateX(-20deg);
    text-shadow: 10px 10px 3px #f022ff,9px 9px 3px #f022ff,8px 8px 2px #f022ff,7px 7px 2px #f022ff,6px 6px 1px #f022ff,5px 5px 1px #f022ff,4px 4px 0 #f022ff,3px 3px 0 #f022ff,2px 2px 0 #f022ff,1px 1px 0 #f022ff
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(179deg, #15b7d1, #3899d2, #854bce, #c907cb);
  background-size: 800% 800%;
  /*Credits for original idea of hotline miami bg: jamesiv.es*/
  animation: ${hotlinebg} 17s ease infinite;
`;

export const GlitchText = styled.div`
  animation: logoRotate linear 6s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  animation-duration: 4s;
  animation-name: ${deadtext};
  font-size: 85px;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-decoration: none;
  color: #fff;

  &:after,
  &:before {
    display: block;
    content: "MTSHV";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
  }

  &:after {
    color: #f0f;
    z-index: -2;
    animation: ${glitch} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both
      infinite;
  }

  &:before {
    color: #0ff;
    z-index: -1;
    animation: ${glitch} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  }
`;

export const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 690px;
`;

export const Paragraph = styled.p`
  font-family: Pixelicious, monospace;
  font-size: 1.5em;
  text-transform: uppercase;
  color: transparent;
  filter: blur(0.3px);
  animation: 2s ease-in-out infinite alternate ${pulse};
`;
