import * as React from "react";
import HotlineMiamiPlaceholder from "../containers/HotlineMiamyPlaceholder"
import 'normalize.css';
import "./index.css"

const IndexPage = () => {
  return (
    <HotlineMiamiPlaceholder />
  )
}

export default IndexPage
