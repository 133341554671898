import * as React from "react";
import {
  Content,
  GlitchText,
  Paragraph,
  Wrapper,
} from "./HotlineMiamyPlaceholder.styles";

const HotlineMiamiPlaceholder = () => {
  return (
    <Wrapper>
      <GlitchText>MTSHV</GlitchText>
      <Content>
        <Paragraph>
          Hi! It's nice that you came by.
          <br />
          ...
        </Paragraph>
        <Paragraph>
          The website is under maintenance
          <br />
          But one day there will be a grand opening! Maybe...
        </Paragraph>
      </Content>
    </Wrapper>
  );
};

export default HotlineMiamiPlaceholder;
